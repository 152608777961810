











































import { defineComponent, reactive, watch } from "@vue/composition-api";
import { isNasabah } from "@/apps/accounts/modules/store";

const headerIconMap = {
  list: "list-alt",
  detail: "address-card",
  create: "plus-square",
  update: "edit",
};

const actionIconMap = {
  add: "plus",
  update: "edit",
  verifikasi: "tasks",
};

export default defineComponent({
  name: "ViewHeader",
  components: {
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
  },
  props: {
    title: { type: String, required: true },
    viewType: String,
    action: Object,
    // action: { type: "add", routeName: "ujian-add", callBack: null }
    contextMenu: Object,
    listData: Object, // jika perlu pagination,
    backBtn: { type: Boolean, default: true}
  },
  setup(props) {
    // watch ketika props.value berubah (selesai loading fetch)
    const actionData = reactive(Object.assign({}, props.action));
    const classHeader = isNasabah.value? "card-header":"card-header header-bg"

    watch(
      () => props.action,
      (newValue) => {
        Object.assign(actionData, newValue);
      },
      { deep: true }
    );
    return {
      actionData,
      actionIconMap,
      classHeader,
      headerIconMap,
      list: props.listData?.list,
      pagination: props.listData?.pagination,
    };
  },
});
